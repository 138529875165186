<template>
  <b-modal
    v-model="dialog"
    title="Defaulter Bypass Request Details"
    no-close-on-backdrop
    size="lg"
    :busy="submitLoader || loading"
    @hidden="$emit('modal-closed')"
  >
    <b-alert
      variant="danger"
      :show="errorMessage ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ errorMessage }}
        </p>
      </div>
    </b-alert>

    <!-- Request Details Table -->
    <b-table-simple
      striped
      hover
      show-empty
      class="compact-table mb-3"
    >
      <b-tr>
        <b-th>Request ID</b-th>
        <b-td>{{ data.id }}</b-td>
        <b-th>Date</b-th>
        <b-td>{{ formatedDate(data.request_time, 'DD-MM-YYYY LT') }}</b-td>
      </b-tr>
      <b-tr>
        <b-th>Buyer</b-th>
        <b-td>{{ data.buyer_details && data.buyer_details.identifier || '-' }}</b-td>
        <b-th>Requested By</b-th>
        <b-td>{{ data.requested_by_details && data.requested_by_details.name || '-' }}</b-td>
      </b-tr>
      <b-tr>
        <b-th>{{ data.status === 'approved' ? 'Approved By' : (data.status === 'rejected' ? 'Rejected By' : 'Approved By') }}</b-th>
        <b-td> {{ data.approved_by_details ? ((data.approved_by_details.first_name || '') + ' ' + (data.approved_by_details.last_name || '')).trim() || '-' : '-' }}
        </b-td>
        <b-th>Status</b-th>
        <b-td>
          <status :data="data.status" />
        </b-td>
      </b-tr>
      <b-tr>
        <b-th>Request Note</b-th>
        <b-td> {{ data.reason }}
        </b-td>

      </b-tr>
    </b-table-simple>

    <!-- Form section if not already approved or rejected -->
    <div v-if="data.status === 'pending'">
      <h5>Bypass Request Form</h5>
      <b-form @submit.prevent>
        <b-form-group
          label="Approval/Rejection note"
          label-for="approval_note"
          label-class="font-weight-bold"
        >
          <b-form-textarea
            id="approvalNote"
            v-model="formData.approvalNote"
            placeholder="Enter note"
            rows="3"
            :state="formData.approvalNote ? true : null"
          />
        </b-form-group>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Duration Value"
              label-for="duration-value"
              label-class="font-weight-bold"
            >
              <b-form-input
                id="duration-value"
                v-model.number="formData.durationValue"
                type="number"
                :min="1"
                :max="formData.durationType === 'hours' ? 24 : 31"
                placeholder="Enter duration value"
                :state="validateDurationValue"
              />
              <b-form-invalid-feedback :state="validateDurationValue">
                {{ formData.durationType === 'hours' ? 'Hours must be between 1-24' : 'Days must be between 1-31' }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Duration Type"
              label-for="duration-type"
              label-class="font-weight-bold"
            >
              <b-form-select
                id="duration-type"
                v-model="formData.durationType"
                :options="durationTypeOptions"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </div>

    <!-- Read-only view of approved details if already approved -->
    <div v-else-if="data.status === 'approved'">
      <h5>Approval Details</h5>
      <b-table-simple
        striped
        hover
        class="compact-table"
      >
        <b-tr>
          <b-th>Approval Note</b-th>
          <b-td>{{ data.approval_note || '-' }}</b-td>
        </b-tr>
        <b-tr>
          <b-th>Duration</b-th>
          <b-td>{{ `${data.duration_value} ${data.duration_type}` }}</b-td>
        </b-tr>
        <b-tr>
          <b-th>Approved At</b-th>
          <b-td>{{ formatedDate(data.approval_time, 'DD-MM-YYYY LT') || '-' }}</b-td>
        </b-tr>
        <b-tr>
          <b-th>Expire At</b-th>
          <b-td>{{ formatedDate(data.valid_until, 'DD-MM-YYYY LT') || '-' }}</b-td>
        </b-tr>
      </b-table-simple>
      <b-form-group
        label="Revocation Reason"
        label-for="approval_note"
        label-class="font-weight-bold"
      >
        <b-form-textarea
          id="revocation_reason"
          v-model="formData.revocation_reason"
          placeholder="Enter reason"
          rows="3"
          :state="formData.revocation_reason ? true : null"
        />
      </b-form-group></div>

    <!-- Read-only view of rejected details if already rejected -->
    <div v-else-if="data.status === 'rejected'">
      <h5>Rejection Details</h5>
      <b-table-simple
        striped
        hover
        class="compact-table"
      >
        <b-tr>
          <b-th>Rejection Note</b-th>
          <b-td>{{ data.approval_note || '-' }}</b-td>
        </b-tr>
        <b-tr>
          <b-th>Rejected At</b-th>
          <b-td>{{ formatedDate(data.rejection_time, 'DD-MM-YYYY LT') || '-' }}</b-td>
        </b-tr>
      </b-table-simple>
    </div>
    <div v-else-if="data.status === 'revoked'">
      <h5>Revocation Details</h5>
      <b-table-simple
        striped
        hover
        class="compact-table"
      >
        <b-tr>
          <b-th>Revocation Note</b-th>
          <b-td>{{ data.revocation_reason || '-' }}</b-td>
        </b-tr>
        <b-tr>
          <b-th>Revoked At</b-th>
          <b-td>{{ formatedDate(data.revoked_time, 'DD-MM-YYYY LT') || '-' }}</b-td>
        </b-tr>
        <b-tr>
          <b-th>{{ 'Revoked By' }}</b-th>
          <b-td> {{ data.revoked_by_details ? ((data.revoked_by_details.first_name || '') + ' ' + (data.revoked_by_details.last_name || '')).trim() || '-' : '-' }}
          </b-td>
        </b-tr>
      </b-table-simple>
    </div>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          v-if="data.status === 'pending'"
          variant="danger"
          class="float-right ml-2"
          :disabled="submitLoader"
          @click="handleReject"
        >
          Reject
        </b-button>

        <b-button
          v-if="data.status === 'pending'"
          variant="primary"
          class="float-right"
          :disabled="!isFormValid || submitLoader"
          @click="handleApprove"
        >
          Approve
        </b-button>
        <b-button
          v-if="data.status === 'approved'"
          variant="danger"
          class="float-right"
          :disabled="submitLoader"
          @click="handleRevoke"
        >
          Revoke
        </b-button>
        <b-button
          variant="secondary"
          @click="dialog = false"
        >
          Close
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import dateMixin from '@/mixins/dateMixin'
import formatMixin from '@/mixins/formatMixin'
import {
  BModal,
  BTableSimple,
  BTr,
  BTd,
  BTh,
  BAlert,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormInvalidFeedback,
  BButton,
  BRow,
  BCol,
  BFormTextarea,
} from 'bootstrap-vue'
import axios from 'axios'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import Status from './Status.vue'

export default {
  components: {
    BModal,
    BTableSimple,
    BTr,
    BTd,
    BTh,
    BAlert,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    Status,
    BFormTextarea,
  },
  mixins: [dateMixin, formatMixin],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      errorMessage: null,
      submitLoader: false,
      formData: {
        buyer: null,
        reason: '',
        durationValue: 1,
        durationType: 'hours',
      },
      durationTypeOptions: [
        { value: 'hours', text: 'Hours' },
        { value: 'days', text: 'Days' },
      ],
    }
  },
  computed: {
    dialog: {
      get() {
        return this.$props.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      },
    },
    validateDurationValue() {
      const { durationValue, durationType } = this.formData
      if (!durationValue) return false

      if (durationType === 'hours') {
        return durationValue >= 1 && durationValue <= 24
      }
      return durationValue >= 1 && durationValue <= 31
    },
    isFormValid() {
      return (
        this.formData.durationValue
        && this.validateDurationValue
      )
    },
  },
  watch: {
    data: {
      handler(newVal) {
        if (newVal) {
          this.formData.buyer = newVal.buyer
          this.formData.approvalNote = newVal.approvalNote || ''
          this.formData.durationValue = newVal.durationValue || 1
          this.formData.durationType = newVal.durationType || 'hours'
          this.formData.revocation_reason = newVal.revocation_reason || ''
        }
      },
      immediate: true,
    },
  },
  methods: {
    async handleApprove() {
      try {
        this.submitLoader = true
        this.errorMessage = null

        await axios.post(`/delivery/defaulter-bypass-requests/${this.data.id}/approve/`, {
          buyer: this.formData.buyer,
          approval_note: this.formData.approvalNote,
          duration_value: this.formData.durationValue,
          duration_type: this.formData.durationType,
        })

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Request approved successfully',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })

        this.$emit('handleUpdate')
      } catch (error) {
        this.errorMessage = error?.response?.data?.details || 'Failed to approve request!'
      } finally {
        this.submitLoader = false
      }
    },

    async handleReject() {
      try {
        this.submitLoader = true
        this.errorMessage = null

        await axios.post(`/delivery/defaulter-bypass-requests/${this.data.id}/reject/`, {
          buyer: this.formData.buyer,
          approval_note: this.formData.approvalNote,
          duration_value: this.formData.durationValue,
          duration_type: this.formData.durationType,
        })

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Request rejected successfully',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })

        this.$emit('handleUpdate')
      } catch (error) {
        this.errorMessage = error?.response?.data?.details || 'Failed to reject request!'
      } finally {
        this.submitLoader = false
      }
    },
    async handleRevoke() {
      try {
        this.submitLoader = true
        this.errorMessage = null

        await axios.post(`/delivery/defaulter-bypass-requests/${this.data.id}/revoke/`, {
          revocation_reason: this.formData.revocation_reason,
        })

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Request revoked successfully',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })

        this.$emit('handleUpdate')
      } catch (error) {
        this.errorMessage = error?.response?.data?.details || 'Failed to reject request!'
      } finally {
        this.submitLoader = false
      }
    },
  },
}
</script>
