<template>
  <div>
    <span
      :class="[`text-${statusVariant}`, `border-${statusVariant}`]"
      class="border rounded bg-white status-badge"
    >
      <span class="font-weight-bold"> {{ capitalizedData }} </span>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    data: { type: String, required: true },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    capitalizedData() {
      return typeof this.data === 'string' ? this.data.toUpperCase() : ''
    },
    statusVariant() {
      if (this.$props.disabled) {
        return 'lighten-2'
      }
      switch (this.data) {
        case 'rejected':
          return 'danger'

        case 'expired':
          return 'secondary'

        case 'approved':
          return 'success'

        case 'pending':
          return 'warning'

        case 'revoked':
          return 'danger'

        default:
          return 'secondary'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.status-badge {
    padding: 2px 12px;
    font-size: 12px;
}
</style>
