var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"label":"Small Spinner","variant":"primary"}})],1):_vm._e(),_c('b-alert',{attrs:{"variant":"danger","show":_vm.errorMessage ? true : false}},[_c('div',{staticClass:"alert-body"},[_c('p',[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])])]),(!(_vm.loading || _vm.errorMessage))?_c('b-table',{staticClass:"compact-table",attrs:{"items":_vm.notesData,"fields":_vm.tableFields,"responsive":"","striped":"","hover":"","show-empty":"","no-local-sorting":"","sort-by":_vm.filters.sortBy,"sort-desc":_vm.filters.sortDesc},on:{"sort-changed":_vm.sortingChanged},scopedSlots:_vm._u([{key:"cell(party__identifier)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.buyer_details.identifier)+" ")]}},{key:"cell(requested_by)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.requested_by_details && item.requested_by_details.name)+" ")]}},{key:"cell(approved_by)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.approved_by_details ? ((item.approved_by_details.first_name || '') + ' ' + (item.approved_by_details.last_name || '')).trim() || '-' : '-'))]}},{key:"cell(requested_at)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatedDate(item.request_time ,'DD-MM-YYYY LT'))+" ")]}},{key:"cell(approved_at)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.approval_time && _vm.formatedDate(item.approval_time ,'DD-MM-YYYY LT'))+" ")]}},{key:"cell(approval_period)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.duration_value) + " " + (item.duration_type)))+" ")]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [_c('status',{attrs:{"data":item.status}})]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({boundary:'window'}),expression:"{boundary:'window'}",modifiers:{"hover":true}}],staticClass:"cursor-pointer",attrs:{"title":"Approve Request","icon":"EditIcon","size":"18"},on:{"click":function($event){return _vm.handleAttachClick(item)}}})]}}],null,false,3325531283)}):_vm._e(),(!_vm.loading && !_vm.errorMessage)?_c('detailed-pagination',{attrs:{"total-items":_vm.totalItems,"per-page":_vm.filters.perPage,"current-page":_vm.filters.page},on:{"page-changed":function (page) {_vm.filters.page = page}}}):_vm._e(),(_vm.attachData)?_c('request-detail',{attrs:{"data":_vm.attachData},on:{"handleUpdate":_vm.handleUpdate},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }