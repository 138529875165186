<template>
  <b-card
    title="Default Party Order Requests"
  >
    <order-requests-filter v-model="filters" />
    <order-requests-list v-model="filters" />
  </b-card>

</template>

<script>
import OrderRequestsFilter from '@/components/DefaultPartyOrderRequests/OrderRequestsFilter.vue'
import OrderRequestsList from '@/components/DefaultPartyOrderRequests/OrderRequestsList.vue'

import { BCard } from 'bootstrap-vue'

export default {
  components: {
    OrderRequestsFilter,
    OrderRequestsList,
    BCard,
  },
  data() {
    return {
      filters: {
        id: null,
        date: null,
        status: { label: 'Pending', value: 'pending' },
        buyer: null,
        created_by: null,
        approved_by: null,
        sortBy: 'date',
        sortDesc: true,
        page: 1,
        perPage: 50,
      },
    }
  },

}
</script>
