<template>
  <b-row>
    <b-col
      cols="12"
      md="3"
      lg="2"
    >
      <b-form-group label="Request Id.">
        <b-form-input
          v-model="filters.id"
          type="text"
          placeholder="Search Request Id"
        />
      </b-form-group>
    </b-col>
    <b-col
      cols="12"
      md="3"
      lg="2"
    >
      <b-form-group label="Date">
        <FlatPicker
          v-model="filters.date"
          placeholder="Select Date Range"
          :config="{ mode: 'range' }"
          @input="handleFilterChange"
        />
      </b-form-group>
    </b-col>
    <b-col
      cols="12"
      md="3"
      lg="2"
    >
      <b-form-group label="Status">
        <custom-v-select
          v-model="filters.status"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="statusOptions"
          placeholder="Select Status"
          :deselect-from-dropdown="true"
          @input="handleFilterChange"
        />
      </b-form-group>
    </b-col>
    <b-col
      cols="12"
      md="3"
      lg="2"
    >
      <b-form-group label="Buyer">
        <custom-v-select
          v-model="filters.buyer"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="buyerOptions"
          label="text"
          placeholder="Select buyer"
          :close-on-select="false"
          :deselect-from-dropdown="true"
          multiple
          :reduce="(option) => option.id"
          @input="handleFilterChange"
        />
      </b-form-group>
    </b-col>
    <b-col
      cols="12"
      md="3"
      lg="2"
    >
      <b-form-group label="Salesman">
        <custom-v-select
          v-model="filters.created_by"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="salesmanList"
          label="text"
          placeholder="Select Salesman"
          :close-on-select="false"
          :reduce="(data => data.id)"
          :deselect-from-dropdown="true"
        />
      </b-form-group>
    </b-col>
    <b-col
      cols="12"
      md="3"
      lg="2"
    >
      <b-form-group label="Approved By">
        <custom-v-select
          v-model="filters.approved_by"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="usersList"
          label="fullname"
          placeholder="Select Approved By"
          :close-on-select="false"
          :reduce="(data => data.id)"
          :deselect-from-dropdown="true"
        />
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { debounce } from 'lodash'
import FlatPicker from '@/components/UI/FlatPicker.vue'
import CustomVSelect from '@/components/UI/CustomVSelect.vue'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    FlatPicker,
    CustomVSelect,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      statusOptions: [
        { label: 'Pending', value: 'pending' },
        { label: 'Approved', value: 'approved' },
        { label: 'Rejected', value: 'rejected' },
        { label: 'Expired', value: 'expired' },
        { label: 'Revoked', value: 'revoked' },
      ],
      buyerOptions: [],
      usersList: [],
      salesmanList: [],
    }
  },

  computed: {
    filters() {
      return this.$props.value
    },
    docNumber: {
      get() {
        return this.filters.docNo
      },
      set(newVal) {
        this.updateFilter({ docNo: newVal })
      },
    },
  },
  mounted() {
    this.fetchOptions()
  },
  methods: {
    updateFilter: debounce(function updateFilter(newFilter) {
      this.$emit('input', {
        ...this.filters,
        ...newFilter,
        page: 1,
      })
    }, 500),
    handleFilterChange() {
      this.$emit('input', {
        ...this.filters,
        page: 1,
      })
    },
    async fetchOptions() {
      try {
        const { data } = await axios.get('/accounts/buyers_options')
        this.buyerOptions = data.data
        const { data: userList } = await axios.get('/delivery/users_list?retailers_only=false')
        this.usersList = userList
        const { data: salesmanList } = await axios.get('/accounts/salesmans_options')
        this.salesmanList = salesmanList.data
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.message || 'Something went wrong!',
            icon: 'CheckIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
}
</script>
