<template>
  <div>
    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        label="Small Spinner"
        variant="primary"
      />
    </div>

    <b-alert
      variant="danger"
      :show="errorMessage ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ errorMessage }}
        </p>
      </div>
    </b-alert>
    <b-table
      v-if="!(loading || errorMessage)"
      :items="notesData"
      :fields="tableFields"
      responsive
      striped
      hover
      show-empty
      class="compact-table"
      no-local-sorting
      :sort-by="filters.sortBy"
      :sort-desc="filters.sortDesc"
      @sort-changed="sortingChanged"
    >
      <template #cell(party__identifier)="{item}">
        {{ item.buyer_details.identifier }}
      </template>
      <template #cell(requested_by)="{item}">
        {{ item.requested_by_details && item.requested_by_details.name }}
      </template>
      <template #cell(approved_by)="{item}">
        {{ item.approved_by_details ? ((item.approved_by_details.first_name || '') + ' ' + (item.approved_by_details.last_name || '')).trim() || '-' : '-' }}</template>
      <template #cell(requested_at)="{item}">
        {{ formatedDate(item.request_time ,'DD-MM-YYYY LT') }}
      </template>
      <template #cell(approved_at)="{item}">
        {{ item.approval_time && formatedDate(item.approval_time ,'DD-MM-YYYY LT') }}
      </template>
      <template #cell(approval_period)="{item}">
        {{ `${item.duration_value}  ${item.duration_type}` }}
      </template>
      <template #cell(status)="{item}">
        <status :data="item.status" />
      </template>
      <template #cell(actions)="{item}">
        <feather-icon
          v-b-tooltip.hover="{boundary:'window'}"
          title="Approve Request"
          icon="EditIcon"
          class="cursor-pointer"
          size="18"
          @click="handleAttachClick(item)"
        />

      </template>
    </b-table>
    <detailed-pagination
      v-if="!loading && !errorMessage"
      :total-items="totalItems"
      :per-page="filters.perPage"
      :current-page="filters.page"
      @page-changed="(page) => {filters.page = page}"
    />
    <request-detail
      v-if="attachData"
      v-model="dialog"
      :data="attachData"
      @handleUpdate="handleUpdate"
    />
  </div>
</template>

<script>
import axios from 'axios'
import {
  BTable, BSpinner, BAlert, VBTooltip,
} from 'bootstrap-vue'
import dateMixin from '@/mixins/dateMixin'
import formatMixin from '@/mixins/formatMixin'
import DetailedPagination from '@/components/UI/DetailedPagination.vue'
import getParsedQueryString from '@/utils/headerQueryParser'
import Status from './Status.vue'
import RequestDetail from './RequestDetail.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BTable, DetailedPagination, BSpinner, BAlert, Status, RequestDetail,
  },
  mixins: [dateMixin, formatMixin],
  props: {
    value: { type: Object, default: () => {} },
  },
  data() {
    return {
      loading: false,
      errorMessage: null,
      notesData: [],
      tableFields: [
        { key: 'id', label: 'Request ID', sortable: true },
        { key: 'party__identifier', label: 'Buyer', sortable: true },
        { key: 'requested_by', label: 'Requested By', sortable: true },
        { key: 'requested_at', label: 'Requested At', sortable: true },
        { key: 'approved_by', label: 'Approved By', sortable: true },
        { key: 'approved_at', label: 'Approved At', sortable: true },
        { key: 'approval_period', label: 'Approval Period', sortable: true },
        { key: 'status', label: 'Payment Status', sortable: true },
        { key: 'actions', label: 'Actions', sortable: false },
      ],
      totalItems: 0,
      dialog: false,
      attachData: null,
    }
  },
  computed: {
    filters: {
      get() {
        return this.$props.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      },
    },
  },
  watch: {
    filters: {
      handler() {
        this.fetchListingData()
      },
      deep: true,
    },
    dialog(newVal) {
      if (!newVal) {
        this.attachData = null
      }
    },
  },
  mounted() {
    this.fetchListingData()
  },
  methods: {
    sortingChanged(ctx) {
      this.filters.sortBy = ctx.sortBy
      this.filters.sortDesc = ctx.sortDesc
    },
    async fetchListingData() {
      try {
        const {
          id,
          date,
          status,
          buyer,
          created_by,
          approved_by,
          sortBy,
          sortDesc,
          page,
          perPage,
        } = this.filters
        this.loading = true
        const [fromDate, toDate] = date?.split(' to ') || ''
        const preparedPayload = {
          id,
          ...(fromDate && toDate
            ? { start_date: fromDate, end_date: toDate }
            : {}),
          buyer,
          created_by,
          approved_by,
          status: status?.value,
          sort_by: sortBy,
          sort_desc: sortDesc,
          page,
          page_size: perPage,
        }

        const response = await axios.get(
          `/delivery/defaulter-bypass-requests?${getParsedQueryString(preparedPayload)}`,
        )
        const { results, count } = response.data
        this.notesData = results
        this.totalItems = count
      } catch (error) {
        this.errorMessage = error?.response?.data?.details || 'Failed to fetch default party order request.'
      } finally {
        this.loading = false
      }
    },
    handleAttachClick(data) {
      this.attachData = data
      this.dialog = true
    },
    handleUpdate() {
      this.dialog = false
      this.fetchListingData()
    },
  },
}
</script>
